<!--
 * @Description: 商品详情
 * @Author: 琢磨先生
 * @Date: 2022-06-07 21:48:59
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-09 02:05:07
-->


<template>
  <el-alert type="info" :closable="false" title="">
    <div class="flex-row">
      <div class="preview">
        <div class="title">页面预览</div>
        <div class="content">
          <el-scrollbar>
            <el-image
              v-for="item in fileList"
              :key="item.id"
              :src="item.file_url"
              fit="fill"
              style="width: 100%"
            ></el-image>
          </el-scrollbar>
        </div>
      </div>
      <div class="quick_decoration">
        <div class="header">
          <div class="header-title">
            <div class="title">快捷编辑</div>
            <div class="help-block">
              仅支持上传图片，最多可上传50张，宽高比例>=1:3,且宽高均大于480px，大小3M内。已上传{{
                fileList.length
              }}/{{ maxCount }}张，拖拽可调整顺序
            </div>
          </div>
        </div>
        <div class="list-body">
          <draggable
            class="draggable_group"
            v-model="fileList"
            group="people"
            @start="drag = true"
            @end="onDragEnd"
            item-key="id"
          >
            <template #item="{ element, index }">
              <div class="image">
                <el-image
                  :src="element.file_url"
                  fit="fill"
                  :preview-src-list="srcList"
                  :initial-index="index"
                ></el-image>
                <el-icon class="icon-del" @click="onRemove(index)"
                  ><Delete
                /></el-icon>
              </div> </template
          ></draggable>
          <psg-image-space
            :type="0"
            @change="chooseChange"
            :count="maxCount - fileList.length"
          ></psg-image-space>
        </div>
      </div>
    </div>
  </el-alert>
</template>

<script>
import PsgImageSpace from "../../psd/psd_image_space.vue";
import draggable from "vuedraggable";
export default {
  components: {
    PsgImageSpace,
    draggable,
  },
  data() {
    return {
      fileList: [],
      srcList: [],
      maxCount: 50,
      drag: false,
    };
  },
  emits: ["change"],
  props: ["goods"],
  watch: {
    goods: {
      handler() {
        if (this.goods) {
          if (this.goods.mobile_decoration) {
            var list = JSON.parse(this.goods.mobile_decoration);
            if (list && list.length > 0) {
              console.log(list);
              list.forEach((item) => {
                this.fileList.push(...item.content_list);
              });
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 移除图片
     * @param {*} i
     */
    onRemove(i) {
      this.fileList.splice(i, 1);
      this.handlerBack();
    },
    /**
     * 拖拽结束
     */
    onDragEnd() {
      this.handlerBack();
    },
    /**
     * 选择图片回调
     */
    chooseChange(values) {
      if (this.fileList.length >= 10) {
        return;
      }
      if (this.maxCount - values.length - this.fileList.length >= 0) {
        this.fileList = this.fileList.concat(values);
      } else {
        for (var i = 0; i < values.length; i++) {
          if (this.fileList.length >= this.maxCount) {
            break;
          }
          this.fileList.push(values[i]);
        }
      }
      this.srcList = this.fileList.map((x) => x.file_url);
      this.handlerBack();
    },

    /**
     * 处理回调
     */
    handlerBack() {
      var decoration_list = [];
      this.fileList.forEach((item) => {
        decoration_list.push({
          type: "image",
          content_list: [
            {
              file_url: item.file_url,
              width: item.width,
              height: item.width,
            },
          ],
        });
      });
      console.log(decoration_list);

      this.$emit("change", decoration_list);
    },
  },
};
</script>

<style scoped>
/* 详情预览 */
.preview {
  width: 375px;
  min-width: 375px;
  height: 600px;
  border: 1px solid var(--el-border-color);
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}
.preview .title {
  height: 50px;
  background: white;
  line-height: 50px;
  padding-left: 20px;
  font-size: 16px;
}

.preview .content {
  height: 1px;
  flex-grow: 1;
}

.preview .content .el-image {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
}

.quick_decoration {
  border: 1px solid var(--el-border-color);
  background: white;
  flex-grow: 1;
}
.quick_decoration .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.quick_decoration .title {
  font-size: 16px;
  margin-bottom: 5px;
}
.quick_decoration .tip {
  width: 100%;
}
.quick_decoration .list-body {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  padding: 0px 20px 20px 20px;
}
/* 图片列表 */
.list-body .image {
  margin: 0 10px 10px 0;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  position: relative;
}

.list-body .image .el-image {
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.list-body .image .el-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #f56c6c;
  display: none;
}

.list-body .image:hover .icon-del.el-icon {
  display: block;
}

/* 拖拽组 */
.draggable_group {
  display: flex;
  flex-flow: wrap;
}
</style>